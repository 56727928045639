<template>
  <Card v-if="value" class="applicant-card" theme="border">
    <template #header>
      <div class="applicant-card__thumbnail" :style="{ backgroundImage: `url('/api/avatar/${initials}` }"></div>
    </template>
    <div class="applicant-card__info">
      {{ value.name }}
      <template v-if="value.isCompany"> ({{ value.companyName }})</template>
    </div>
    <div class="applicant-card__contact">
      <div>{{ value.email }}</div>
    </div>
    <template #footer>
      <slot name="footer"></slot>
    </template>
  </Card>
</template>

<script>
import { Initials } from '@/utils'

import Card from '@/components/Card'

export default {
  props: {
    value: Object,
  },
  computed: {
    initials() {
      return Initials(this.value.name)
    },
  },
  components: {
    Card,
  },
}
</script>

<style lang="scss">
.applicant-card {
  text-align: center;
  min-width: 300px;

  .card__header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: none;
  }

  .card__footer-content {
    justify-content: center;
  }

  &__info {
    font-weight: bold;
  }

  a {
    color: var(--primary);
    text-decoration: none;

    svg {
      margin-right: 5px;
    }
  }

  &__thumbnail {
    $size: 125px;

    width: $size;
    height: $size;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    flex-shrink: 0;
    background-size: cover;
    background-position: center;

    background-color: css-alpha('border-color', 0.4);
    border-radius: 50%;
    color: css-darken('border-color', 20%);
  }
}
</style>
