import moment from 'moment'

function Initials(val) {
  return (val || '')
    .split(' ')
    .map(v => v.substring(0, 1))
    .join('')
    .toUpperCase()
}

function Moment(val, format = 'lll') {
  return moment(val).format(format)
}

function Limit(val, limit = 30) {
  if (val.length < limit) return val
  return `${val.substr(0, limit)}...`
}

function Currency(val, locale = 'nb', currency = 'NOK', decimals = 0) {
  if (!val) return
  return val.toLocaleString(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: decimals,
  })
}

export { Initials, Moment, Currency, Limit }
